// Process *this*, _this_ and ~that~

// 0x5f: _
// 0x2a: *
// 0x7e: ~

// Insert each marker as a separate text token, and add it to delimiter list

const SYMBOLS = [0x5f, 0x2a, 0x7e];

const SYMBOLS_TAGS = {
  0x5f: 'em',
  0x2a: 'strong',
  0x7e: 's',
};

function emphasisTokenize(state, silent) {
  const marker = state.src.charCodeAt(state.pos);
  if (silent || !SYMBOLS.includes(marker)) return false;

  const scanned = state.scanDelims(state.pos /* marker === 0x2a */);
  if (scanned.length !== 1) return false;

  for (let i = 0; i < scanned.length; i += 1) {
    const token = state.push('text', '', 0);
    token.content = String.fromCharCode(marker);

    state.delimiters.push({
      marker, // Char code of the starting marker (number).
      length: scanned.length, // Total length of these series of delimiters.
      token: state.tokens.length - 1, // A position of the token this delimiter corresponds to.
      end: -1, // If this delimiter is matched as a valid opener, `end` will be equal to its position, otherwise it's `-1`.
      open: scanned.can_open, // Boolean flags that determine if this delimiter could open or close an emphasis.
      close: scanned.can_close,
    });
  }

  state.pos += scanned.length;
  return true;
}

const updateToken = (token, startDelim, isOpen) => {
  const markerTag = SYMBOLS_TAGS[startDelim.marker];
  token.type = isOpen ? `${markerTag}_open` : `${markerTag}_close`;
  token.tag = markerTag;
  token.nesting = isOpen ? 1 : -1;
  token.markup = String.fromCharCode(startDelim.marker);
  token.content = '';
};

function postProcess(state, delimiters) {
  for (let i = delimiters.length - 1; i >= 0; i -= 1) {
    const startDelim = delimiters[i];

    if (SYMBOLS.includes(startDelim.marker) && startDelim.end !== -1) {
      const endDelim = delimiters[startDelim.end];

      const tokenOpen = state.tokens[startDelim.token];
      const tokenClose = state.tokens[endDelim.token];

      if (!tokenOpen || !tokenClose) return;

      const tokensBetween = [...state.tokens].slice(
        startDelim.token + 1,
        endDelim.token
      );
      if (tokensBetween.some((token) => token.tag === 'br')) return;

      updateToken(tokenOpen, startDelim, true);
      updateToken(tokenClose, startDelim, false);
    }
  }
}

// Walk through delimiter list and replace text tokens with tags
function emphasisPostProcess(state) {
  const tokensMeta = state.tokens_meta;
  postProcess(state, state.delimiters);

  for (let curr = 0; curr < tokensMeta.length; curr += 1) {
    if (tokensMeta[curr] && tokensMeta[curr].delimiters) {
      postProcess(state, tokensMeta[curr].delimiters);
    }
  }
}

export default {
  tokenize: emphasisTokenize,
  postProcess: emphasisPostProcess,
};
