<template>
  <div class="message-template-info__container">
    <div class="message-template-info__title">
      {{ messageTemplate.title }}
    </div>

    <template v-if="!disableActions">
      <div class="message-template-info__actions">
        <woot-button
          v-if="hasSendButton"
          class="message-template-info__actions__button"
          color-scheme="primary"
          icon="send"
          :is-disabled="!isSendButtonEnabled"
          data-testid="toggle-show-send-template-modal"
          @click="toggleShowSendPopup"
        >
          {{ $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.SEND_TO_LIST') }}
        </woot-button>

        <woot-button
          color-scheme="secondary"
          icon="copy"
          variant="smooth"
          data-testid="toggle-show-clone-template-modal"
          @click="toggleShowCloneModal"
        />

        <woot-button
          color-scheme="alert"
          icon="delete"
          variant="smooth"
          data-testid="toggle-show-delete-template-modal"
          @click="toggleShowDeletePopup"
        />
      </div>

      <teleport to="#chatlyn-app-target">
        <div>
          <woot-modal
            :show.sync="showSendPopup"
            :on-close="closeShowSendPopup"
            size="lg"
            overflow="scrollable"
          >
            <send-message-template-modal
              :message-template="messageTemplate"
              @close="closeShowSendPopup"
            />
          </woot-modal>

          <woot-delete-modal
            :show.sync="showDeletePopup"
            :title="$t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.TITLE')"
            :message="
              $t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.MESSAGE', {
                LANGUAGE_COUNT: messageTemplate.translations.length,
              })
            "
            :confirm-text="$t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.ACCEPT')"
            :reject-text="$t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.DECLINE')"
            :on-confirm="deleteMessageTemplate"
            :on-close="toggleShowDeletePopup"
            :is-loading="isDeleting"
            :close-on-backdrop-click="false"
            :has-buttons="!messageTemplatesEntrypoints.length"
          >
            <template v-if="messageTemplatesEntrypoints.length" #body>
              <div class="callout warning mb-4 flex flex-row gap-2 text-xs">
                <span>
                  <fluent-icon icon="lock-closed" :size="12" />
                </span>

                <span>
                  <div>
                    {{
                      $t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.ALERT.TITLE')
                    }}

                    <ul class="list-disc">
                      <li
                        v-for="entrypoint in messageTemplatesEntrypoints"
                        :key="entrypoint.entrypointId"
                        class="ml-6"
                      >
                        {{ entrypoint.name }}
                      </li>
                    </ul>

                    {{ $t('CHATLYN_MESSAGE_TEMPLATE.DELETE_MODAL.ALERT.TEXT') }}
                  </div>
                </span>
              </div>
            </template>
          </woot-delete-modal>

          <woot-modal
            :show.sync="showCloneModal"
            :on-close="toggleShowCloneModal"
            :close-on-backdrop-click="false"
            max-width="100%"
            width="50rem"
            overflow="auto"
          >
            <create-message-template-modal
              clone-mode
              edit-mode
              :preselected-message-template-type="messageTemplateType"
              :fetch-message-templates="fetchMessageTemplates"
              :fetch-message-template="fetchMessageTemplate"
              @close="toggleShowCloneModal"
            />
          </woot-modal>
        </div>
      </teleport>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

import Teleport from 'vue2-teleport';

import CreateMessageTemplateModal from './CreateMessageTemplateModal.vue';
import SendMessageTemplateModal from './SendMessageTemplateModal.vue';
import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from '../constants';

export default {
  name: 'MessageTemplateInfoActions',
  components: {
    SendMessageTemplateModal,
    CreateMessageTemplateModal,
    Teleport,
  },
  mixins: [alertMixin],
  props: {
    messageTemplate: {
      type: Object,
      default: () => {},
    },

    disableActions: {
      type: Boolean,
      default: false,
    },

    messageTemplateType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDeletePopup: false,
      showSendPopup: false,
      showCloneModal: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      uiFlags: 'messageTemplates/getUIFlags',
      messageTemplatesEntrypoints:
        'messageTemplates/getMessageTemplatesEntrypoints',
    }),

    isDeleting() {
      return this.uiFlags.isDeleting;
    },

    isSendButtonEnabled() {
      return (
        (this.messageTemplate?.translations?.some(
          (translation) => translation.status === 'approved'
        ) ||
          this.messageTemplate.status === 'approved') &&
        !this.isConsent
      );
    },

    isConsent() {
      return this.messageTemplate?.category === 'CONSENT';
    },

    hasSendButton() {
      return this.messageTemplateType !== MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS;
    },
  },
  watch: {
    'messageTemplate._id': {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.fetchMessageTemplateEntrypoints();
      },
      immediate: true,
    },
  },
  methods: {
    openMessageTemplate() {
      this.$emit('open-message-template-modal');
    },

    toggleShowDeletePopup() {
      this.showDeletePopup = !this.showDeletePopup;
    },

    toggleShowSendPopup() {
      if (this.isConsent) {
        return;
      }

      this.showSendPopup = !this.showSendPopup;
    },

    closeShowSendPopup() {
      this.showSendPopup = false;
    },

    fetchMessageTemplates() {
      this.$emit('fetch-message-templates');
    },

    fetchMessageTemplate(id) {
      this.$emit('fetch-message-template', id);
    },

    toggleShowCloneModal() {
      this.showCloneModal = !this.showCloneModal;
    },

    async deleteMessageTemplate() {
      let result;

      switch (this.messageTemplateType) {
        case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
          result = await this.$store.dispatch(
            'messageTemplates/deleteSmsMessageTemplate',
            {
              // eslint-disable-next-line no-underscore-dangle
              templateId: this.messageTemplate._id,
            }
          );
          break;

        case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
          result = await this.$store.dispatch(
            'messageTemplates/deleteMessageTemplate',
            {
              // eslint-disable-next-line no-underscore-dangle
              templateId: this.messageTemplate._id,
            }
          );
          break;

        default:
          break;
      }

      this.$emit('delete-template', result);
    },

    async fetchMessageTemplateEntrypoints() {
      const result = await this.$store.dispatch(
        'messageTemplates/getMessageTemplatesEntrypoints',
        {
          accountId: this.accountId,
          // eslint-disable-next-line no-underscore-dangle
          templateId: this.messageTemplate._id,
        }
      );

      if (!result) {
        this.showAlert(
          this.$t('CHATLYN_MESSAGE_TEMPLATE.PANEL.ENTRYPOINTS_ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-template-info__container {
  @apply flex flex-col flex-nowrap gap-[0.625rem];

  .message-template-info__title {
    @apply text-lg leading-[1.39] text-woot-900 dark:text-woot-50;
  }

  .message-template-info__actions {
    @apply flex flex-row flex-nowrap items-center justify-start gap-[0.625rem];

    .message-template-info__actions__button {
      @apply flex flex-1 items-center justify-center;

      ::v-deep .button__content {
        @apply w-auto;
      }
    }
  }
}
</style>
