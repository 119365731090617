import { mapGetters } from 'vuex';
import { SYSTEM_ATTRIBUTES_KEYS } from '../constants/common';
import { isValidURL } from '../helper/URLHelper';

export default {
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
    }),
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        this.attributeType
      );
    },
    attributesSubtitles() {
      return [
        {
          label: this.translateAttribute('SYSTEM_ATTRIBUTES'),
          value: 'system_attributes',
          disabled: true,
        },
        {
          label: this.translateAttribute('CUSTOM_ATTRIBUTES'),
          value: 'custom_attributes',
          disabled: true,
        },
      ];
    },
    contactCustomAttributes() {
      return this.attributes.map((customAttr) => ({
        label: customAttr.attribute_display_name,
        value: `contacts.custom_attributes.${customAttr.attribute_key}`,
        type: customAttr.attribute_display_type,
        options: customAttr.attribute_values.length
          ? customAttr.attribute_values
          : null,
      }));
    },
    isConversationRoute() {
      return this.$route.name === 'inbox_conversation';
    },
    customAttributes() {
      if (this.attributeType === 'conversation_attribute')
        return this.currentChat.custom_attributes || {};
      return this.contact?.custom_attributes || {};
    },
    contactIdentifier() {
      return (
        this.contactId ||
        this.currentChat.meta?.sender?.id ||
        this.$route.params.contactId
      );
    },
    contact() {
      if (this.$route.name === 'contacts_lists_dashboard') {
        return this.$store.getters['contactLists/getCurrentContact'](
          this.contactId
        );
      }
      return this.$store.getters['contacts/getContact'](
        this.isConversationRoute ? this.contactIdentifier : this.contactId
      );
    },
    conversationId() {
      return this.currentChat.id;
    },
    fallbackItems() {
      return [
        {
          label: this.$t('CHATLYN_MESSAGE_TEMPLATE.ATTRIBUTES.NO_OPTIONS'),
          disabled: true,
        },
      ];
    },
    systemAttributes() {
      return Object.entries(SYSTEM_ATTRIBUTES_KEYS).map(([key, value]) => ({
        label: this.translateAttribute(key),
        type: 'text',
        value: value,
      }));
    },
    filteredAttributes() {
      return Object.keys(this.customAttributes).map((key) => {
        const item = this.attributes.find(
          (attribute) => attribute.attribute_key === key
        );
        if (item) {
          return {
            ...item,
            value: this.customAttributes[key],
          };
        }

        return {
          ...item,
          value: this.customAttributes[key],
          attribute_description: key,
          attribute_display_name: key,
          attribute_display_type: this.attributeDisplayType(
            this.customAttributes[key]
          ),
          attribute_key: key,
          attribute_model: this.attributeType,
          id: Math.random(),
        };
      });
    },
  },
  methods: {
    isAttributeNumber(attributeValue) {
      return (
        Number.isInteger(Number(attributeValue)) && Number(attributeValue) > 0
      );
    },
    attributeDisplayType(attributeValue) {
      if (this.isAttributeNumber(attributeValue)) {
        return 'number';
      }
      if (isValidURL(attributeValue)) {
        return 'link';
      }
      return 'text';
    },
    filterContactAttrs(attrs, { filterByKey, excludedValues, includedValues }) {
      if (filterByKey && excludedValues) {
        return attrs.filter(
          (attr) => !excludedValues.includes(attr[filterByKey])
        );
      }
      if (filterByKey && includedValues) {
        return attrs.filter((attr) =>
          includedValues.includes(attr[filterByKey])
        );
      }
      return [...attrs];
    },
    getAttributesList(filter = {}) {
      const systemList = this.filterContactAttrs(this.systemAttributes, filter);
      if (systemList.length) {
        systemList.unshift(this.attributesSubtitles[0]);
      }
      const customList = this.filterContactAttrs(
        this.contactCustomAttributes,
        filter
      );
      if (customList.length) {
        customList.unshift(this.attributesSubtitles[1]);
      }
      const filteredAttributes = [...systemList, ...customList];
      return filteredAttributes.length
        ? filteredAttributes
        : this.fallbackItems;
    },
    translateAttribute(path) {
      return this.$t('CHATLYN_MESSAGE_TEMPLATE.ATTRIBUTES.' + path);
    },
  },
};
