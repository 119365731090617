<template>
  <formulate-form
    class="column content-box create-message-template-modal"
    name="create-message-template-form"
    :class="{
      'create-message-template-modal__rtl-view': currentLanguage === 'ar',
    }"
  >
    <woot-modal-header
      :header-title="$t(modalHeader.title)"
      :tool-tip-text="$t(modalHeader.tooltip)"
    />

    <div class="create-message-template-modal-content">
      <type-step
        v-if="currentStep === CREATE_MESSAGE_TEMPLATE_STEP.TYPE"
        :message-template-type="messageTemplateType"
        @change-message-template-type="changeMessageTemplateType"
      />

      <main-info-step
        v-if="currentStep === CREATE_MESSAGE_TEMPLATE_STEP.MAIN"
        :message-template="newMessageTemplate"
        :message-template-type="messageTemplateType"
        @change-message-template="changeMessageTemplate"
      />

      <languages-step
        v-else-if="
          currentStep === CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES &&
          !isLimitExceeded
        "
        :languages="languages"
        :message-template="newMessageTemplate"
        :has-errors="isSomeFormHasErrors"
        :edit-mode="editMode"
        :available-languages="availableLanguages"
        :current-language="currentLanguage"
        :message-template-type="messageTemplateType"
        @change-current-language="changeCurrentLanguage"
        @change-message-template="changeMessageTemplate"
      />
    </div>

    <template v-if="!isLoading">
      <div
        v-show="isSmsNotEnabledForSmsTemplate"
        class="callout alert m-8 p-6 text-center"
      >
        <div class="text-lg font-semibold">
          {{
            $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SMS_NOT_ENABLED.TITLE')
          }}
        </div>

        <div class="mt-1 text-sm">
          {{ $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SMS_NOT_ENABLED.DESC') }}
        </div>
      </div>

      <div v-show="isLimitExceeded" class="callout alert m-8 p-6 text-center">
        <div class="text-lg font-semibold">
          {{ $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.LIMIT_EXCEEDED.TITLE') }}
        </div>

        <div class="mt-1 text-sm">
          {{ $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.LIMIT_EXCEEDED.DESC') }}
        </div>
      </div>
    </template>

    <div
      class="create-message-template-modal-footer"
      :class="{
        'justify-between': !editMode,
        'justify-end': editMode,
      }"
    >
      <template v-if="hasBackButton">
        <woot-button
          v-if="!editMode"
          variant="smooth"
          color-scheme="secondary"
          icon="arrow-left"
          @click="goToPreviousStep"
        >
          {{ $t('CHATLYN_GENERAL.BUTTON.BACK') }}
        </woot-button>
      </template>

      <template v-if="hasNextButton">
        <woot-button
          class="ms-auto flex-row-reverse"
          color-scheme="success"
          :is-disabled="isSomeFormHasErrors"
          icon="arrow-right"
          @click="goToNextStep"
        >
          {{ $t('CHATLYN_GENERAL.BUTTON.NEXT') }}
        </woot-button>
      </template>

      <span
        v-if="CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES === currentStep"
        class="flex flex-row flex-nowrap items-center gap-2"
      >
        <span
          v-if="!isSmsMessageTemplate"
          class="create-message-template-modal-footer-item-container"
        >
          <woot-button
            class="create-message-template-modal-footer-item-button"
            :class="{
              'rounded-e-none pe-2': translationCount > 1,
            }"
            variant="smooth"
            color-scheme="secondary"
            type="button"
            :is-disabled="isDraftButtonDisabled"
            @click="saveTemplate(true)"
          >
            {{ $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.SAVE_ALL_DRAFT') }}
          </woot-button>

          <popover-select
            v-show="translationCount > 1"
            v-model="selectedDraftOption"
            :options="draftOptions"
            :has-search="false"
          >
            <woot-button
              class="create-message-template-modal-footer-item-popover-activator"
              variant="smooth"
              color-scheme="secondary"
              icon="chevron-down"
              type="button"
            />
          </popover-select>
        </span>

        <div class="create-message-template-modal-footer-item-container">
          <woot-button
            class="create-message-template-modal-footer-item-button"
            :class="{
              'rounded-e-none pe-2': translationCount > 1,
            }"
            color-scheme="success"
            type="button"
            :is-disabled="isSubmitButtonDisabled"
            @click="saveTemplate(false)"
          >
            {{ $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.PUBLISH_ALL') }}
          </woot-button>

          <popover-select
            v-show="translationCount > 1"
            v-model="selectedSaveOption"
            :options="saveOptions"
            :has-search="false"
          >
            <woot-button
              class="create-message-template-modal-footer-item-popover-activator"
              color-scheme="success"
              icon="chevron-down"
              type="button"
            />
          </popover-select>
        </div>
      </span>
    </div>

    <chatlyn-spinner :is-visible="isLoading" />
  </formulate-form>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';

import {
  CH_I18N_TO_META_LANGUAGES_KEYS,
  CREATE_MESSAGE_TEMPLATE_STEP,
  CREATE_MESSAGE_TEMPLATE_STEP_HEADER,
  MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE,
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
  MESSAGE_TEMPLATES_CHANNEL_TYPE_DEFAULT,
  MESSAGE_TEMPLATES_LANGUAGES_LABELS,
} from '../constants';
import LanguagesStep from './CreateMessageTemplateModalSteps/LanguagesStep/LanguagesStep.vue';
import MainInfoStep from './CreateMessageTemplateModalSteps/MainInfoStep/MainInfoStep.vue';
import getUuid from 'widget/helpers/uuid';
import PopoverSelect from '../../../../../shared/components/ui/PopoverSelect.vue';
import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';
import TypeStep from './CreateMessageTemplateModalSteps/TypeStep/TypeStep.vue';

export default {
  name: 'CreateMessageTemplateModal',

  components: {
    LanguagesStep,
    MainInfoStep,
    PopoverSelect,
    ChatlynSpinner,
    TypeStep,
  },

  mixins: [alertMixin],

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },

    cloneMode: {
      type: Boolean,
      default: false,
    },

    editLanguage: {
      type: String,
      default: '',
    },

    fetchMessageTemplate: {
      type: Function,
      default: () => {},
    },

    fetchMessageTemplates: {
      type: Function,
      default: () => {},
    },

    preselectedMessageTemplateType: {
      type: String,
      default: '',
    },
  },

  data() {
    const isTypePreselected = Object.values(
      MESSAGE_TEMPLATES_CHANNEL_TYPE
    ).includes(this.preselectedMessageTemplateType);

    const templateType = isTypePreselected
      ? this.preselectedMessageTemplateType
      : MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP;

    const startStep = isTypePreselected
      ? CREATE_MESSAGE_TEMPLATE_STEP.MAIN
      : CREATE_MESSAGE_TEMPLATE_STEP.TYPE;

    return {
      CREATE_MESSAGE_TEMPLATE_STEP,
      MESSAGE_TEMPLATES_LANGUAGES_LABELS,
      currentStep: startStep,
      newMessageTemplate: MESSAGE_TEMPLATES_CHANNEL_TYPE_DEFAULT[templateType],
      formsComponents: [],
      languages: [],
      currentLanguage: '',
      messageTemplateType: templateType,
    };
  },

  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
      messageTemplate: 'messageTemplates/getMessageTemplate',
      uiFlags: 'messageTemplates/getUIFlags',
      messageTemplatesCount: 'messageTemplates/getMessageTemplatesCount',
      userLanguage: 'management/getUserLanguage',
      managementAccount: 'management/getAccount',
      managementSms: 'management/getSms',
    }),

    currentAccountLocale() {
      const { locale: accountLocale } = this.getAccount(this.currentAccountId);

      return accountLocale;
    },

    isLoading() {
      return this.uiFlags.isUpdating || this.uiFlags.isCreating;
    },

    modalHeader() {
      try {
        return CREATE_MESSAGE_TEMPLATE_STEP_HEADER[this.currentStep][
          this.messageTemplateType
        ];
      } catch (error) {
        return {};
      }
    },

    isMainFromHasErrors() {
      const component = this.formsComponents.find(
        (formsComponent) =>
          formsComponent.name === 'create-message-template-form'
      );

      return component ? component.hasErrors : true;
    },

    isSomeFormHasErrors() {
      if (!this.formsComponents?.length) {
        return false;
      }

      return this.formsComponents.some(
        (formsComponent) => formsComponent.hasErrors
      );
    },

    isCurrentLanguageFormHasError() {
      if (!this.currentTranslation) {
        return true;
      }

      const matchedLanguage = this.languages.find(
        (language) => language.name === this.currentLanguage
      );

      if (!matchedLanguage) {
        return true;
      }

      return !matchedLanguage.completed;
    },

    formComponentsHasErrors() {
      const errors = {};

      this.formsComponents.forEach((formComponent) => {
        if (formComponent) {
          errors[formComponent.name] = formComponent.hasErrors;
        }
      });

      return errors;
    },

    availableLanguages() {
      let result = [];

      if (!this.managementAccount) {
        return result;
      }

      const { additionalLang } = this.managementAccount;

      result = additionalLang.filter((language) =>
        MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE.includes(language)
      );

      if (
        !this.languages?.some(
          (language) => language.name === this.currentAccountLocale
        ) &&
        !this.additionalLang?.some(
          (language) => language === this.currentAccountLocale
        )
      ) {
        result.push(this.currentAccountLocale);
      }

      return result;
    },

    isLimitExceeded() {
      if (!this.messageTemplatesCount) {
        return false;
      }

      const matchedTemplateCount = this.messageTemplatesCount?.whatsapp?.find(
        (whatsapp) =>
          whatsapp.phoneNumber === this.newMessageTemplate.phoneNumber
      );

      if (!matchedTemplateCount) {
        return false;
      }

      return matchedTemplateCount.count >= 250;
    },

    draftOptions() {
      return [
        {
          label: this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.BUTTON.SAVE_LANGUAGE_DRAFT',
            {
              LANGUAGE: (
                MESSAGE_TEMPLATES_LANGUAGES_LABELS[this.currentLanguage] ||
                this.currentLanguage
              ).toUpperCase(),
            }
          ),
          value: 'save_language_draft',
          disabled: this.currentTranslation
            ? ['approved', 'rejected', 'submitted', 'pending'].includes(
                this.currentTranslation.status
              )
            : true,
        },
      ];
    },

    saveOptions() {
      return [
        {
          label: this.$t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.PUBLISH_LANGUAGE', {
            LANGUAGE: (
              MESSAGE_TEMPLATES_LANGUAGES_LABELS[this.currentLanguage] ||
              this.currentLanguage
            ).toUpperCase(),
          }),
          value: 'publish_language',
          disabled:
            this.isCurrentLanguageFormHasError ||
            (this.currentTranslation &&
              ['approved', 'submitted', 'pending'].includes(
                this.currentTranslation.status
              )),
        },
      ];
    },

    selectedDraftOption: {
      get() {
        return '';
      },

      async set(newValue) {
        switch (newValue) {
          case 'save_language_draft':
            await this.saveLanguage(true, this.currentLanguage);
            break;

          default:
            break;
        }
      },
    },

    selectedSaveOption: {
      get() {
        return '';
      },

      async set(newValue) {
        switch (newValue) {
          case 'publish_language':
            this.saveLanguage(false, this.currentLanguage);
            break;

          default:
            break;
        }
      },
    },

    currentTranslation() {
      return this.newMessageTemplate?.translations?.find(
        (translation) => translation.language === this.currentLanguage
      );
    },

    isDraftButtonDisabled() {
      return false;
    },

    isSubmitButtonDisabled() {
      return (
        !this.newMessageTemplate?.translations?.length ||
        this.isSomeFormHasErrors
      );
    },

    hasNextButton() {
      if (this.isSmsNotEnabledForSmsTemplate) {
        return false;
      }

      return [
        CREATE_MESSAGE_TEMPLATE_STEP.MAIN,
        CREATE_MESSAGE_TEMPLATE_STEP.TYPE,
      ].includes(this.currentStep);
    },

    hasBackButton() {
      const steps = [CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES];

      if (
        !this.preselectedMessageTemplateType &&
        !this.editMode &&
        !this.cloneMode
      ) {
        steps.push(CREATE_MESSAGE_TEMPLATE_STEP.MAIN);
      }

      return steps.includes(this.currentStep);
    },

    isSmsMessageTemplate() {
      return this.messageTemplateType === MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS;
    },

    isSmsNotEnabledForSmsTemplate() {
      return (
        this.isSmsMessageTemplate &&
        (!this.managementSms?.enabled ||
          !this.managementSms?.shortNames?.length)
      );
    },

    translationCount() {
      return this.newMessageTemplate?.translations?.length || 0;
    },
  },

  watch: {
    formComponentsHasErrors: {
      handler(newValue) {
        if (this.currentStep === this.CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES) {
          this.languages = this.languages.map((language) => ({
            ...language,
            completed:
              !newValue[`create-message-template-form-${language.name}`],
          }));
        }
      },
      deep: true,
    },

    'newMessageTemplate.translations': {
      handler(newValue, prevValue) {
        if (newValue?.length === prevValue?.length) {
          return;
        }

        this.languages = newValue.map((translation) => ({
          name: translation.language,
          completed: false,
        }));

        if (
          !this.languages.some(
            (language) => language.name === this.currentLanguage
          )
        ) {
          this.changeCurrentLanguage(this.languages[0].name);
        }

        this.$nextTick(() => {
          this.setupFormulateFormComponents();
        });
      },
      deep: true,
    },

    currentStep: {
      handler() {
        this.$nextTick(() => {
          this.setupFormulateFormComponents();
        });
      },
      immediate: true,
    },

    messageTemplateType: {
      async handler(newValue, prevValue) {
        if (!prevValue) {
          return;
        }

        const template = structuredClone(
          MESSAGE_TEMPLATES_CHANNEL_TYPE_DEFAULT[newValue]
        );

        this.newMessageTemplate = {
          ...template,
          translations: [
            {
              language: this.currentAccountLocale,
              components: [
                {
                  type: 'BODY',
                  text: '',
                },
              ],
              links: {
                body: [],
                buttons: [],
              },
            },
          ],
        };

        this.currentLanguage = this.currentAccountLocale;

        await this.fetchTemplatesCount();
      },
    },
  },

  mounted() {
    this.languages = [
      {
        name:
          CH_I18N_TO_META_LANGUAGES_KEYS[this.currentAccountLocale] ||
          this.currentAccountLocale,
        completed: false,
      },
    ];

    this.currentLanguage = this.currentAccountLocale;

    this.initialSetup();
  },

  methods: {
    changeMessageTemplateType(newValue) {
      this.messageTemplateType = newValue;
    },

    goToPreviousStep() {
      switch (this.currentStep) {
        case CREATE_MESSAGE_TEMPLATE_STEP.MAIN:
          this.goToTypeStep();
          break;

        case CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES:
          this.goToMainStep();
          break;

        default:
          break;
      }
    },

    goToNextStep() {
      switch (this.currentStep) {
        case CREATE_MESSAGE_TEMPLATE_STEP.TYPE:
          this.goToMainStep();
          break;

        case CREATE_MESSAGE_TEMPLATE_STEP.MAIN:
          this.goToLanguagesStep();
          break;

        default:
          break;
      }
    },

    //

    goToTypeStep() {
      this.currentStep = CREATE_MESSAGE_TEMPLATE_STEP.TYPE;
    },

    goToMainStep() {
      this.currentStep = CREATE_MESSAGE_TEMPLATE_STEP.MAIN;
    },

    goToLanguagesStep() {
      this.currentStep = CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES;
    },

    changeCurrentLanguage(language) {
      this.currentLanguage = language;
    },

    close() {
      this.$emit('close');
    },

    async saveLanguage(isDraft, language) {
      const matchedTranslation = {
        ...this.newMessageTemplate.translations.find(
          (translation) => translation.language === language
        ),
      };

      if (
        this.messageTemplateType === MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP
      ) {
        matchedTranslation.locked = !isDraft;
      }

      if (isDraft && matchedTranslation.status === 'rejected') {
        return;
      }

      if (this.translationCount === 1) {
        this.saveTemplate(isDraft);
        return;
      }

      const languageForAlert =
        MESSAGE_TEMPLATES_LANGUAGES_LABELS[
          matchedTranslation.language?.toLowerCase()
        ]?.toUpperCase() || matchedTranslation.language;

      const oldMessageTemplate = structuredClone(this.newMessageTemplate);

      const newTemplate = this.removeTempIdFromButtons({
        ...this.newMessageTemplate,
        translations: [matchedTranslation],
        clientId: this.currentAccountId,
      });

      try {
        // eslint-disable-next-line no-underscore-dangle
        if (!this.newMessageTemplate._id) {
          let response;

          switch (this.messageTemplateType) {
            case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
              response = await this.$store.dispatch(
                'messageTemplates/createSmsMessageTemplate',
                {
                  accountId: this.currentAccountId,
                  newTemplate,
                }
              );
              break;

            case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
              response = await this.$store.dispatch(
                'messageTemplates/createMessageTemplate',
                {
                  accountId: this.currentAccountId,
                  newTemplate,
                }
              );
              break;

            default:
              break;
          }

          if (!response) {
            throw new Error();
          }

          let newTranslations = [...response.translations];

          oldMessageTemplate.translations.forEach((translation) => {
            if (
              newTranslations.some(
                (newTranslation) =>
                  newTranslation.language === translation.language
              )
            ) {
              return;
            }

            newTranslations.push(translation);
          });

          newTranslations = newTranslations.map((translation) => ({
            ...translation,
            components: this.addIdToButtonComponent(
              translation.components,
              response.category === 'CONSENT'
            ),
          }));

          this.newMessageTemplate = {
            ...response,
            translations: newTranslations,
          };
        } else {
          let newTranslation;

          switch (this.messageTemplateType) {
            case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
              newTranslation = await this.$store.dispatch(
                'messageTemplates/addSmsMessageTemplateTranslation',
                {
                  // eslint-disable-next-line no-underscore-dangle
                  templateId: this.newMessageTemplate._id,
                  translation: newTemplate.translations[0],
                }
              );
              break;

            case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
              newTranslation = await this.$store.dispatch(
                'messageTemplates/addMessageTemplateTranslation',
                {
                  // eslint-disable-next-line no-underscore-dangle
                  templateId: this.newMessageTemplate._id,
                  translation: newTemplate.translations[0],
                }
              );
              break;

            default:
              break;
          }

          if (!newTranslation) {
            throw new Error();
          }

          this.newMessageTemplate = {
            ...oldMessageTemplate,
            translations: oldMessageTemplate.translations
              .map((translation) => {
                if (newTranslation.language !== translation.language) {
                  return translation;
                }

                return newTranslation;
              })
              .map((translation) => ({
                ...translation,
                components: this.addIdToButtonComponent(
                  translation.components,
                  oldMessageTemplate.category === 'CONSENT'
                ),
              })),
          };
        }

        this.showAlert(
          this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TRANSLATION_SUCCESS_MESSAGE',
            {
              LANGUAGE: languageForAlert,
            }
          )
        );

        await this.fetchMessageTemplates();
        // eslint-disable-next-line no-underscore-dangle
        await this.fetchMessageTemplate(this.newMessageTemplate._id);
      } catch (error) {
        this.showAlert(
          this.$t(
            'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TRANSLATION_ERROR_MESSAGE',
            {
              LANGUAGE: languageForAlert,
            }
          )
        );
      }
    },

    async saveTemplate(isDraft) {
      if (isDraft && this.isMainFromHasErrors) {
        return;
      }
      if (!isDraft && this.isSomeFormHasErrors) {
        return;
      }

      this.showAlert(
        this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SUBMIT_START_MESSAGE', {
          TEMPLATE_NAME: this.newMessageTemplate.title,
        })
      );

      // eslint-disable-next-line no-underscore-dangle
      if ((this.editMode && !this.cloneMode) || this.newMessageTemplate._id) {
        this.editMessageTemplate(isDraft);
      } else {
        this.createMessageTemplate(isDraft);
      }
    },

    removeTempIdFromButtons(messageTemplate) {
      let newMessageTemplate = structuredClone(messageTemplate);

      newMessageTemplate.translations = newMessageTemplate.translations.map(
        (translation) => {
          let newTranslation = structuredClone(translation);

          newTranslation.components = newTranslation.components
            .map((component) => {
              if (component.type !== 'BUTTONS') {
                return component;
              }

              if (!component.buttons?.length) {
                return undefined;
              }

              return {
                ...component,
                buttons: component.buttons.map((button) => {
                  delete button.id;
                  delete button.isConsent;

                  return button;
                }),
              };
            })
            .filter((component) => !!component);

          return newTranslation;
        }
      );

      return newMessageTemplate;
    },

    async createMessageTemplate(draft) {
      try {
        const newTemplate = this.removeTempIdFromButtons({
          ...this.newMessageTemplate,
          clientId: this.currentAccountId,
          translations: this.newMessageTemplate.translations.map(
            (translation) => {
              const statusToSkip = ['approved', 'pending', 'submitted'];

              if (draft) {
                statusToSkip.push('rejected');
              }

              if (statusToSkip.includes(translation.status)) {
                return translation;
              }

              if (
                this.messageTemplateType ===
                MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP
              ) {
                translation.locked = !draft;
              }

              return translation;
            }
          ),
        });

        this.close();

        switch (this.messageTemplateType) {
          case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
            await this.$store.dispatch(
              'messageTemplates/createSmsMessageTemplate',
              {
                accountId: this.currentAccountId,
                newTemplate,
              }
            );
            break;

          case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
            await this.$store.dispatch(
              'messageTemplates/createMessageTemplate',
              {
                accountId: this.currentAccountId,
                newTemplate,
              }
            );
            break;

          default:
            break;
        }

        this.showAlert(
          this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SUCCESS_MESSAGE', {
            TEMPLATE_NAME: this.newMessageTemplate.title,
          })
        );

        await this.fetchMessageTemplates();
        // eslint-disable-next-line no-underscore-dangle
        await this.fetchMessageTemplate(this.newMessageTemplate._id);
      } catch (error) {
        this.showAlert(
          this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR_MESSAGE', {
            TEMPLATE_NAME: this.newMessageTemplate.title,
          })
        );
      }
    },

    async editMessageTemplate(draft) {
      try {
        const newTemplate = this.removeTempIdFromButtons({
          ...this.newMessageTemplate,
          translations: this.newMessageTemplate.translations.map(
            (translation) => {
              const statusToSkip = ['approved', 'pending', 'submitted'];

              if (draft) {
                statusToSkip.push('rejected');
              }

              if (statusToSkip.includes(translation.status)) {
                return translation;
              }

              if (
                this.messageTemplateType ===
                MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP
              ) {
                translation.locked = !draft;
              }

              return translation;
            }
          ),
        });

        this.close();

        switch (this.messageTemplateType) {
          case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
            await this.$store.dispatch(
              'messageTemplates/updateSmsMessageTemplate',
              {
                // eslint-disable-next-line no-underscore-dangle
                templateId: newTemplate._id,
                template: newTemplate,
              }
            );

            break;

          case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
            await this.$store.dispatch(
              'messageTemplates/updateMessageTemplate',
              {
                // eslint-disable-next-line no-underscore-dangle
                templateId: newTemplate._id,
                template: newTemplate,
              }
            );
            break;

          default:
            break;
        }

        this.showAlert(
          this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SUCCESS_MESSAGE', {
            TEMPLATE_NAME: this.newMessageTemplate.title,
          })
        );

        await this.fetchMessageTemplates();
        // eslint-disable-next-line no-underscore-dangle
        await this.fetchMessageTemplate(this.newMessageTemplate._id);
      } catch (error) {
        this.showAlert(
          this.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.ERROR_MESSAGE', {
            TEMPLATE_NAME: this.newMessageTemplate.title,
          })
        );
      }
    },

    addIdToButtonComponent(components, isConsent = undefined) {
      const newComponents = structuredClone(components);

      return newComponents.map((component) => {
        if (component.type === 'BUTTONS') {
          const newComponent = {
            ...component,
            buttons: component?.buttons?.map((button) => ({
              ...button,
              id: getUuid(),
            })),
          };

          if (isConsent) {
            newComponent.isConsent = isConsent;
          }

          return newComponent;
        }

        return component;
      });
    },

    setupFormulateFormComponents() {
      this.$nextTick(() => {
        switch (this.currentStep) {
          case CREATE_MESSAGE_TEMPLATE_STEP.TYPE:
          case CREATE_MESSAGE_TEMPLATE_STEP.MAIN:
            this.formsComponents = ['create-message-template-form'].map(
              (formName) => this.$formulate.registry.get(formName)
            );
            break;

          case CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES:
            this.formsComponents = [
              'create-message-template-form',
              ...this.newMessageTemplate.translations.map((transition) => {
                return `create-message-template-form-${transition.language}`;
              }),
            ].map((formName) => {
              return this.$formulate.registry.get(formName);
            });
            break;

          default:
            break;
        }
      });
    },

    changeMessageTemplate(newValue) {
      this.newMessageTemplate = structuredClone(newValue);
    },

    async fetchTemplatesCount() {
      switch (this.messageTemplateType) {
        case MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS:
          await this.$store.dispatch('messageTemplates/getSmsTemplatesCount', {
            accountId: this.currentAccountId,
          });
          break;

        case MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP:
          await this.$store.dispatch('messageTemplates/getTemplatesCount', {
            accountId: this.currentAccountId,
          });
          break;

        default:
          break;
      }
    },

    async initialSetup() {
      await this.$store.dispatch('management/getSms', {
        accountId: this.currentAccountId,
      });

      if (this.editMode && this.messageTemplate) {
        const messageTemplateCopy = structuredClone(this.messageTemplate);

        if (this.cloneMode) {
          messageTemplateCopy.title = '';

          const FIELDS_TO_DELETE = [
            '_id',
            'name',
            'status',
            'rejectedReason',
            'locked',
            'createdAt',
            'updatedAt',
            'statusUpdatedAt',
          ];

          FIELDS_TO_DELETE.forEach((field) => {
            delete messageTemplateCopy[field];
          });

          messageTemplateCopy.translations =
            messageTemplateCopy.translations.map((translation) => {
              FIELDS_TO_DELETE.forEach((field) => {
                delete translation[field];
              });

              return translation;
            });
        }

        const newLanguages = [];

        messageTemplateCopy.translations = messageTemplateCopy.translations.map(
          (translation) => {
            const newComponents = this.addIdToButtonComponent(
              translation.components,
              messageTemplateCopy.category === 'CONSENT'
            );

            const newTranslation = {
              links: {
                body: [],
                buttons: [],
              },
              ...translation,
              components: newComponents,
            };

            newLanguages.push({
              name: newTranslation.language,
              completed: false,
            });

            return newTranslation;
          }
        );

        this.newMessageTemplate = messageTemplateCopy;
        this.languages = newLanguages;

        if (!this.cloneMode) {
          this.currentStep = this.CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES;
        }
      }

      if (!this.editMode) {
        this.newMessageTemplate.translations = this.languages.map(
          (language) => ({
            language: language.name,
            components: [
              {
                type: 'BODY',
                text: '',
              },
            ],
            links: {
              body: [],
              buttons: [],
            },
          })
        );
      }

      await this.fetchTemplatesCount();

      await this.$store.dispatch('management/getAccount', {
        accountId: this.currentAccountId,
      });

      this.$nextTick(() => {
        this.currentLanguage = this.editLanguage || this.languages[0].name;
      });
    },
  },
};
</script>

<style lang="scss">
.create-message-template-modal {
  @apply relative overflow-visible;

  &.create-message-template-modal__rtl-view {
    input:not([type='tel']),
    textarea {
      direction: rtl !important;
    }
  }

  .create-message-template-modal-content {
    @apply relative z-10 block w-full gap-5 px-8 py-4;
  }

  .create-message-template-modal-footer {
    @apply flex w-full gap-3 px-8 pb-8 pt-4;

    .create-message-template-modal-footer-item-container {
      @apply relative flex flex-nowrap;

      .create-message-template-modal-footer-item-popover-activator {
        @apply rounded-s-none px-0;
      }
    }
  }
}
</style>
