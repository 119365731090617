import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state = {
  records: [],
  record: {},
  excludedContacts: {
    items: [],
    meta: {},
  },
  meta: {},
  links: {},
  entrypoints: [],
  appliedListConditions: {},
  uiFlags: {
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isDuplicating: false,
    isMerging: false,
    isExporting: false,
    isExcludedFetching: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
