import {
  LocalStorage,
  LOCAL_STORAGE_KEYS,
} from 'dashboard/helper/localStorage';
import types from '../../mutation-types';
import { PARTNERS } from 'dashboard/constants/partners';

const mapUpdatedPersonality = ({ personality, contactProp }) => ({
  [contactProp]: personality.identifier,
});

const mapDefaultPersonalities = (brandnamicPersonalitiesMap) =>
  Object.entries(brandnamicPersonalitiesMap).reduce((acc, [key, value]) => {
    if (value.length) {
      acc[key] = value[0]?.identifier ?? '';
    }
    return acc;
  }, {});

export const mutations = {
  [types.SET_BRANDNAMIC_CONTACTS]: (state, payload) => {
    state.brandnamicContacts = payload;
  },
  [types.SET_BRANDNAMIC_META]: (state, payload) => {
    state.brandnamicMeta = payload.pagination ?? {};
  },
  [types.SET_BRANDNAMIC_CONTACT]: (state, payload) => {
    if (payload) {
      state.brandnamicContact = { ...payload, partner: PARTNERS.BRANDNAMIC };
    }
  },
  [types.SET_BRANDNAMIC_FETCHING_UI_FLAG]: (state, payload) => {
    state.uiFlags.isFetching = payload;
  },
  [types.SET_BRANDNAMIC_PERSONALITIES_MAP]: (state, { contacts, filter }) => {
    const isPhone = !!filter?.customer_phone;
    const filterValues = isPhone
      ? filter.customer_phone
      : filter.customer_email ?? [];
    const newContacts = {};
    filterValues.forEach((value) => {
      newContacts[value] = contacts.filter((contact) =>
        isPhone ? contact.phone_number === value : contact.email === value
      );
    });
    state.brandnamicPersonalitiesMap = {
      ...state.brandnamicPersonalitiesMap,
      ...newContacts,
    };
  },
  [types.SET_BRANDNAMIC_ACTIVE_PERSONALITY]: (state, contactProp) => {
    const personalities = state.brandnamicPersonalitiesMap[contactProp];
    const activePersonality = personalities?.find(
      (personality) =>
        personality.identifier ===
        state.brandnamicSelectedPersonalities[contactProp]
    );
    state.brandnamicActivePersonality = activePersonality ?? null;
  },
  [types.SET_SELECTED_PERSONALITIES]: (state, updatedPersonality) => {
    const selectedPersonalities = updatedPersonality
      ? mapUpdatedPersonality(updatedPersonality)
      : mapDefaultPersonalities(state.brandnamicPersonalitiesMap);

    const savedPersonalities =
      LocalStorage.get(LOCAL_STORAGE_KEYS.PERSONALITIES) ?? {};

    const updatedPersonalities = updatedPersonality
      ? { ...savedPersonalities, ...selectedPersonalities }
      : { ...selectedPersonalities, ...savedPersonalities };

    LocalStorage.set(LOCAL_STORAGE_KEYS.PERSONALITIES, updatedPersonalities);
    state.brandnamicSelectedPersonalities = updatedPersonalities;
  },
};
