import { BrandnamicApi } from 'dashboard/api/brandnamic';
import types from '../../mutation-types';

export const actions = {
  fetchBrandnamicContact: async ({ commit }, { id, body }) => {
    commit(types.SET_BRANDNAMIC_FETCHING_UI_FLAG, true);
    try {
      const { data } = await BrandnamicApi.fetchBrandnamicContacts(id, body);

      commit(types.SET_BRANDNAMIC_CONTACT, data.contacts[0]);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BRANDNAMIC_FETCHING_UI_FLAG, false);
    }
  },
  fetchBrandnamicContacts: async ({ commit }, { id, body }) => {
    commit(types.SET_BRANDNAMIC_FETCHING_UI_FLAG, true);
    try {
      const { data } = await BrandnamicApi.fetchBrandnamicContacts(id, body);
      const contacts = data?.contacts ?? data?.payload ?? [];
      commit(types.SET_BRANDNAMIC_CONTACTS, contacts);
      commit(types.SET_BRANDNAMIC_META, data?.meta ?? {});
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BRANDNAMIC_FETCHING_UI_FLAG, false);
    }
  },
  fetchBrandnamicPersonalitiesMap: async ({ commit }, { id, body }) => {
    commit(types.SET_BRANDNAMIC_FETCHING_UI_FLAG, true);
    try {
      const { data } = await BrandnamicApi.fetchBrandnamicContacts(id, body);
      commit(types.SET_BRANDNAMIC_PERSONALITIES_MAP, {
        contacts: data.contacts,
        filter: body.filter,
      });
      commit(types.SET_SELECTED_PERSONALITIES);
      commit(types.SET_BRANDNAMIC_CONTACTS, data.contacts);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_BRANDNAMIC_FETCHING_UI_FLAG, false);
    }
  },
  setSelectedPersonalities: ({ commit }, payload) => {
    commit(types.SET_SELECTED_PERSONALITIES, payload);
    commit(types.SET_BRANDNAMIC_ACTIVE_PERSONALITY, payload.contactProp);
  },
  setBrandnamicActivePersonality: ({ commit }, payload) => {
    commit(types.SET_BRANDNAMIC_ACTIVE_PERSONALITY, payload);
  },
};
