<template>
  <div v-if="entrypoint" class="flex flex-grow overflow-auto dark:bg-gray-900">
    <div
      class="chatlyn-container flex w-full flex-col gap-8 md:grid md:grid-cols-3"
    >
      <div class="col-span-1 flex flex-col gap-4">
        <div class="entrypoint-card">
          <div class="mb-2 text-ellipsis text-xl">
            {{ entrypoint.name }}
          </div>

          <div>
            <woot-button
              type="button"
              variant="smooth"
              color-scheme="secondary"
              size="small"
              icon="edit"
              data-testid="edit-entrypoint"
              @click="toggleIsEditModalShown"
            >
              {{ $t('CHATLYN_GENERAL.BUTTON.EDIT') }}
            </woot-button>
            <woot-button
              type="button"
              variant="smooth"
              color-scheme="secondary"
              size="small"
              icon="duplicate"
              data-testid="duplicate-entrypoint"
              @click="toggleIsDuplicateModalShown(entrypoint._id)"
            >
              {{ $t('CHATLYN_GENERAL.BUTTON.DUPLICATE') }}
            </woot-button>

            <woot-button
              v-tooltip="
                $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.REMOVE_ENTRY_POINT')
              "
              type="button"
              variant="smooth"
              color-scheme="alert"
              size="small"
              icon="delete"
              data-testid="delete-entrypoint"
              @click="toggleIsDeleteModalShown"
            />
          </div>
        </div>

        <div v-if="entrypoint.apiUrl" class="entrypoint-card">
          <div class="flex flex-col flex-nowrap gap-3">
            <input-copy :value="entrypoint.apiUrl">
              <formulate-input
                v-model="entrypoint.apiUrl"
                :label="
                  $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.ENTRYPOINT_URL')
                "
                readonly
              />
            </input-copy>

            <input-copy :value="entrypoint.apiKey">
              <formulate-input
                v-model="entrypoint.apiKey"
                :label="
                  $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.ENTRYPOINT_KEY')
                "
                readonly
              />
            </input-copy>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex flex-col gap-4">
        <label>
          {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.ENTRYPOINT_AUTOMATIONS') }}
        </label>

        <template
          v-if="entrypoint.automations && entrypoint.automations.length"
        >
          <div
            v-for="automation in entrypoint.automations"
            :key="automation._id"
            class="entrypoint-card"
          >
            <div
              class="flex flex-row flex-nowrap items-center justify-between gap-2"
            >
              <span class="text-ellipsis text-base">
                {{ automation.name }}
              </span>

              <span class="flex flex-row gap-2">
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary"
                  icon="edit"
                  data-testid="edit-automation"
                  @click="navigateToAutomationBuilder(automation._id)"
                >
                  {{
                    $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.EDIT_IN_BUILDER')
                  }}
                </woot-button>
                <woot-button
                  type="button"
                  variant="smooth"
                  color-scheme="secondary"
                  size="small"
                  icon="duplicate"
                  data-testid="duplicate-automation"
                  @click="toggleIsDuplicateModalShown(automation._id)"
                >
                  {{ $t('CHATLYN_GENERAL.BUTTON.DUPLICATE') }}
                </woot-button>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="delete"
                  data-testid="delete-automation"
                  @click="toggleIsAutomationDeleteModalShown(automation._id)"
                />
              </span>
            </div>

            <div class="text-sm">
              <div class="flex flex-row flex-nowrap items-center gap-3">
                <div
                  class="box-border inline-flex flex-nowrap items-center gap-1 rounded border p-1 text-xs dark:border-gray-700 dark:bg-gray-800"
                >
                  <span
                    class="status-indicator"
                    :class="{
                      'bg-green-600': !automation.paused,
                      'bg-red-600': automation.paused,
                    }"
                  />
                  {{
                    $t(
                      automation.paused
                        ? 'CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.AUTOMATION_STATUS.PAUSED'
                        : 'CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.AUTOMATION_STATUS.ACTIVE'
                    )
                  }}
                </div>

                <span class="txt-muted">·</span>

                <div class="flex gap-1">
                  <span class="txt-muted">
                    {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.VERSION') }}
                  </span>
                  {{ automation.version }}
                </div>
              </div>
            </div>
          </div>
          <woot-button
            class="mx-auto mt-3"
            color-scheme="secondary"
            type="button"
            icon="add-circle"
            data-testid="create-automation"
            @click="toggleIsCreateAutomationModalShown"
          >
            {{
              $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.AUTOMATIONS_NEW_BUTTON')
            }}
          </woot-button>
        </template>

        <div
          v-else
          class="callout border py-10 text-center dark:border-gray-800"
        >
          <div class="text-xl">
            {{
              $t(
                'CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.AUTOMATIONS_EMPTY_STATE.TITLE'
              )
            }}
          </div>

          <div class="my-3 text-sm txt-muted">
            {{
              $t(
                'CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.AUTOMATIONS_EMPTY_STATE.DESC'
              )
            }}
          </div>

          <div>
            <woot-button
              color-scheme="secondary"
              icon="add-circle"
              type="button"
              data-testid="create-automation"
              @click="toggleIsCreateAutomationModalShown"
            >
              {{
                $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_PAGE.AUTOMATIONS_NEW_BUTTON')
              }}
            </woot-button>
          </div>
        </div>
      </div>

      <entrypoint-edit-modal
        v-if="isEditModalShown"
        :entrypoint="entrypoint"
        :is-visible="isEditModalShown"
        @close="toggleIsEditModalShown"
      />

      <entrypoint-duplicate-modal
        v-if="isDuplicateModalShown"
        :is-visible="isDuplicateModalShown"
        :name="duplicatedName"
        @duplicate="onDuplicateEntrypoint"
        @close="toggleIsDuplicateModalShown"
      />

      <woot-modal
        :show.sync="isCreateAutomationModalShown"
        :on-close="toggleIsCreateAutomationModalShown"
        :close-on-backdrop-click="false"
      >
        <create-automation-modal
          v-if="isCreateAutomationModalShown"
          :entrypoint-id="entrypointId"
          @close="toggleIsCreateAutomationModalShown"
          @navigate-to-automation-builder="navigateToAutomationBuilder"
        />
      </woot-modal>

      <delete-modal
        v-if="isAnyDeleteModalShown"
        :show="isAnyDeleteModalShown"
        :title="deleteModalTitle"
        :message="deleteModalMessage"
        :reject-text="$t('CHATLYN_GENERAL.BUTTON.CANCEL')"
        :confirm-text="$t('CHATLYN_GENERAL.BUTTON.DELETE')"
        :on-close="closeAnyModal"
        :on-confirm="confirmAnyDeleteModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputCopy from 'dashboard/components/ui/InputCopy';
import DeleteModal from 'dashboard/components/widgets/modal/DeleteModal';
import CreateAutomationModal from './CreateAutomationModal';
import EntrypointEditModal from './EntrypointEditModal';
import EntrypointDuplicateModal from './EntrypointDuplicateModal';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'Entrypoint',
  components: {
    InputCopy,
    EntrypointDuplicateModal,
    EntrypointEditModal,
    DeleteModal,
    CreateAutomationModal,
  },
  mixins: [alertMixin],
  props: {
    entrypointId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      entrypoint: null,
      selectedAutomationId: '',
      duplicatedId: '',
      isEditModalShown: false,
      isDeleteModalShown: false,
      isDuplicateModalShown: false,
      isAutomationDeleteModalShown: false,
      isCreateAutomationModalShown: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      oldEntrypoint: 'entrypoint/getEntrypoint',
    }),

    selectedAutomation() {
      return this.entrypoint?.automations?.find(
        // eslint-disable-next-line no-underscore-dangle
        (automation) => automation._id === this.selectedAutomationId
      );
    },

    isAnyDeleteModalShown() {
      return this.isDeleteModalShown || this.isAutomationDeleteModalShown;
    },
    deleteModalTitle() {
      return this.isAutomationDeleteModalShown
        ? this.$t('CHATLYN_AUTOMATIONS.DELETE_MODAL.AUTOMATION_TITLE')
        : this.$t('CHATLYN_AUTOMATIONS.DELETE_MODAL.ENTRYPOINT_TITLE');
    },

    deleteModalMessage() {
      if (this.isDeleteModalShown) {
        return this.$t('CHATLYN_AUTOMATIONS.DELETE_MODAL.ENTRYPOINT_DESC', {
          NAME: this.entrypoint?.name || '',
        });
      }

      if (this.isAutomationDeleteModalShown) {
        return this.$t('CHATLYN_AUTOMATIONS.DELETE_MODAL.AUTOMATION_DESC', {
          NAME: this.selectedAutomation?.name || '',
        });
      }

      return '';
    },
    duplicatedJourney() {
      return this.entrypoint.automations.find(
        ({ _id }) => _id === this.duplicatedId
      );
    },
    duplicatedName() {
      if (this.duplicatedId) {
        return this.duplicatedJourney
          ? this.duplicatedJourney.name
          : this.entrypoint.name;
      }
      return '';
    },
  },
  watch: {
    entrypointId: {
      async handler(newValue) {
        if (!newValue) {
          this.entrypoint = null;
          return;
        }

        await this.$store.dispatch('entrypoint/getEntrypoint', {
          accountId: this.currentAccountId,
          id: this.entrypointId,
        });
      },
      immediate: true,
    },

    oldEntrypoint: {
      async handler(newValue) {
        if (!newValue || !Object.keys(newValue).length) {
          return;
        }

        await this.setupEntrypoint();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleIsDeleteModalShown() {
      this.isDeleteModalShown = !this.isDeleteModalShown;
    },
    toggleIsDuplicateModalShown(duplicatedId = '') {
      this.isDuplicateModalShown = !this.isDuplicateModalShown;
      this.duplicatedId = duplicatedId;
    },

    toggleIsAutomationDeleteModalShown(id) {
      this.isAutomationDeleteModalShown = !this.isAutomationDeleteModalShown;
      this.selectedAutomationId = id;
    },

    async toggleIsEditModalShown(reSetup = false) {
      this.isEditModalShown = !this.isEditModalShown;

      if (typeof reSetup === 'boolean' && reSetup) {
        await this.$store.dispatch('entrypoint/getEntrypoint', {
          accountId: this.currentAccountId,
          id: this.entrypointId,
        });
      }
    },

    toggleIsCreateAutomationModalShown() {
      this.isCreateAutomationModalShown = !this.isCreateAutomationModalShown;
    },

    closeAnyModal() {
      if (this.isDeleteModalShown) {
        this.toggleIsDeleteModalShown();
      }

      if (this.isAutomationDeleteModalShown) {
        this.toggleIsAutomationDeleteModalShown('');
      }
    },

    async confirmAnyDeleteModal() {
      if (this.isDeleteModalShown) {
        await this.deleteEntrypoint();
      }

      if (this.isAutomationDeleteModalShown) {
        await this.deleteAutomation();
      }
    },

    async setupEntrypoint() {
      this.entrypoint = JSON.parse(JSON.stringify(this.oldEntrypoint));
    },

    onDuplicateSuccess({ _id: entrypointId } = {}) {
      if (this.duplicatedJourney) {
        this.refetchEntrypoint();
      } else {
        this.refetchEntrypoints();
        this.$router.replace({
          params: {
            accountId: this.currentAccountId,
            entrypointId,
          },
        });
      }
      this.showAlert(
        this.$t('CHATLYN_AUTOMATIONS.NOTIFICATIONS.DUPLICATE_SUCCESS', {
          name: this.duplicatedName,
        })
      );
    },
    getDuplicate(newName, duplicatedData = this.entrypoint) {
      const { _id, ...rest } = duplicatedData;
      const updated = { ...rest, name: newName };
      return JSON.parse(JSON.stringify(updated));
    },
    async onDuplicateEntrypoint(name) {
      try {
        const { data } = await this.$store.dispatch('entrypoint/duplicate', {
          accountId: this.currentAccountId,
          entrypointId: this.entrypointId,
          duplicated: {
            name,
            id: this.duplicatedId,
          },
        });
        this.onDuplicateSuccess(data);
      } catch (errorMsg) {
        this.showErrorAlert(errorMsg);
      }
      this.toggleIsDuplicateModalShown();
    },

    async deleteEntrypoint() {
      try {
        await this.$store.dispatch('entrypoint/deleteEntrypoint', {
          accountId: this.currentAccountId,
          id: this.entrypointId,
        });

        await this.refetchEntrypoints();

        this.$router.replace({
          name: 'automations_dashboard',
          params: {
            accountId: this.currentAccountId,
          },
        });
        this.showAlert(
          this.$t('CHATLYN_AUTOMATIONS.NOTIFICATIONS.DELETE_SUCCESS')
        );
      } catch (errorMsg) {
        this.showErrorAlert(errorMsg);
      }

      this.toggleIsDeleteModalShown();
    },

    async deleteAutomation() {
      try {
        await this.$store.dispatch('entrypoint/deleteEntrypointAutomation', {
          accountId: this.currentAccountId,
          id: this.entrypointId,
          automationId: this.selectedAutomationId,
        });
        await this.refetchEntrypoint();
      } catch (errorMsg) {
        this.showErrorAlert(errorMsg);
      }

      this.toggleIsAutomationDeleteModalShown('');
    },

    async refetchEntrypoints() {
      await this.$store.dispatch('entrypoint/getAllEntrypoint', {
        accountId: this.currentAccountId,
      });
    },
    async refetchEntrypoint() {
      await this.$store.dispatch('entrypoint/getEntrypoint', {
        accountId: this.currentAccountId,
        id: this.entrypointId,
      });
    },
    showErrorAlert(errorMsg) {
      this.showAlert(
        this.$t('CHATLYN_AUTOMATIONS.NOTIFICATIONS.ERROR', { errorMsg })
      );
    },

    navigateToAutomationBuilder(id) {
      this.$router.push({
        name: 'automations_builder',
        params: {
          accountId: this.currentAccountId,
          // eslint-disable-next-line no-underscore-dangle
          entrypointId: this.entrypoint._id,
          automationId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.entrypoint-card {
  @apply flex flex-col gap-2 rounded border bg-white p-3 dark:border-gray-700 dark:bg-gray-800 lg:p-4;
}
</style>
