var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-template__panel absolute right-0 z-10 h-full w-[var(--contact-panel-width)] overflow-y-auto border-l bg-white text-sm shadow-xl dark:border-gray-800/50 dark:bg-gray-900"},[_c('div',{staticClass:"flex flex-row flex-nowrap items-center justify-end px-2 pt-2"},[_c('woot-button',{staticClass:"close-button",attrs:{"variant":"clear","color-scheme":"secondary","icon":"dismiss"},on:{"click":_vm.close}})],1),_vm._v(" "),(_vm.messageTemplate)?_c('div',{staticClass:"message-template__panel__body -mt-2 flex flex-col gap-4 px-4"},[_c('message-template-info-actions',{attrs:{"message-template":_vm.messageTemplate,"message-template-type":_vm.messageTemplateType},on:{"open-message-template-modal":_vm.editMessageTemplate,"delete-template":_vm.deleteTemplate,"fetch-message-templates":_vm.fetchMessageTemplates,"fetch-message-template":_vm.fetchMessageTemplate}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-nowrap gap-3"},[_c('language-bar',{attrs:{"languages":_vm.languages,"current-language":_vm.currentLanguage,"translations":_vm.messageTemplate.translations,"has-additional-text":false,"custom-mark-color":_vm.currentTranslationStatusOptionColor,"has-color-mark":_vm.hasLanguageBarColorMark,"has-add-button":"","has-popover":false},on:{"change":_vm.selectCurrentLanguage,"handle-add-button-click":_vm.toggleShowEditModal}}),_vm._v(" "),_c('message-preview',{staticClass:"message-template__panel__body__preview",attrs:{"has-label":false,"modal-mode":false,"current-translation":_vm.currentTranslation}}),_vm._v(" "),_c('div',{staticClass:"flex flex-row flex-nowrap gap-2"},[_c('woot-button',{staticClass:"message-template__action-button",attrs:{"variant":"smooth","color-scheme":"secondary","icon":"edit","size":"small","is-disabled":_vm.isEditButtonDisabled},on:{"click":_vm.editMessageTemplate}},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.EDIT_LANGUAGE', {
              LANGUAGE: _vm.currentLanguage.toUpperCase(),
            }))+"\n        ")]),_vm._v(" "),(_vm.hasDeleteTranslationLanguageButton)?_c('woot-button',{staticClass:"message-template__action-button",attrs:{"variant":"smooth","color-scheme":"alert","icon":"delete","size":"small"},on:{"click":_vm.toggleShowDeleteLanguageModal}},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.DELETE_LANGUAGE', {
              LANGUAGE: _vm.currentLanguage.toUpperCase(),
            }))+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"message-template__info flex flex-col gap-1 py-4"},[_c('div',[_c('span',[_c('fluent-icon',{attrs:{"icon":"whatsapp","size":16}})],1),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t(
                `CHATLYN_MESSAGE_TEMPLATE.LIST.INBOX_TYPE.${_vm.messageTemplateType.toUpperCase()}`
              ))+"\n          ")])]),_vm._v(" "),(_vm.messageTemplateStatus)?_c('div',[_c('span',[_c('fluent-icon',{attrs:{"icon":"stack","size":16}})],1),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(`${_vm.messageTemplateStatus} ${_vm.$t(
                'CHATLYN_MESSAGE_TEMPLATE.TEMPLATE'
              )}`)+"\n          ")])]):_vm._e(),_vm._v(" "),_c('div',[_c('span',[_c('fluent-icon',{attrs:{"icon":"calendar-empty","size":16}})],1),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.currentTranslationCreatedAt)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-2"},[_c('span',{staticClass:"status-indicator mx-0.5",style:({
              'background-color': _vm.currentTranslationStatusOptionColor,
            })}),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.currentTranslationStatusOptionText)+"\n          ")])]),_vm._v(" "),(_vm.statusReason)?_c('div',{staticClass:"-mt-1 ps-6"},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REJECT_MESSAGE', {
              REASON: _vm.statusReason,
            }))+"\n        ")]):_vm._e()])],1)],1):_vm._e(),_vm._v(" "),_c('teleport',{attrs:{"to":"#chatlyn-app-target"}},[_c('div',[_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteLanguageModal,"title":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.TITLE', {
            LANGUAGE: _vm.currentLanguage.toUpperCase(),
          }),"message":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.MESSAGE'),"confirm-text":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.ACCEPT'),"reject-text":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.REMOVE_TRANSLATION_MODAL.DECLINE'),"on-confirm":_vm.deleteLanguage,"on-close":_vm.toggleShowDeleteLanguageModal,"is-loading":_vm.isDeleting,"close-on-backdrop-click":false},on:{"update:show":function($event){_vm.showDeleteLanguageModal=$event}}})],1)]),_vm._v(" "),_c('chatlyn-spinner',{attrs:{"is-visible":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }