<template>
  <aside class="flex h-full">
    <primary-sidebar
      :logo-source="globalConfig.logoThumbnail"
      :installation-name="globalConfig.installationName"
      :is-a-custom-branded-instance="isACustomBrandedInstance"
      :account-id="accountId"
      :menu-items="primaryMenuItems"
      :active-menu-item="activePrimaryMenu.key"
      @toggle-accounts="toggleAccountModal"
      @key-shortcut-modal="toggleKeyShortcutModal"
      @open-notification-panel="openNotificationPanel"
    />
    <div
      :class="{
        'slide-in': isConversationActive
          ? isConversationSecondarySidebarShowing
          : showSecondarySidebar,
        'slide-out': isConversationActive
          ? !isConversationSecondarySidebarShowing
          : !showSecondarySidebar,
      }"
    >
      <secondary-sidebar
        :class="sidebarClassName"
        :account-id="accountId"
        :inboxes="inboxes"
        :labels="labels"
        :teams="teams"
        :custom-views="customViews"
        :menu-config="activeSecondaryMenu"
        :current-user="currentUser"
        :is-on-chatwoot-cloud="isOnChatwootCloud"
        @add-label="showAddLabelPopup"
        @add-list="showAddListPopup"
        @add-entrypoint="showAddEntrypointPopup"
        @add-folder="showAddFolderPopup"
        @edit-list="showEditListPopup"
        @delete-list="showDeleteListPopup"
        @archive-item="showArchivePopup"
        @edit-folder="showEditFolderPopup"
        @delete-folder="showDeleteFolderPopup"
        @toggle-accounts="toggleAccountModal"
      />
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../mixins/isAdmin';
import { getSidebarItems } from './config/default-sidebar';
import alertMixin from 'shared/mixins/alertMixin';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import PrimarySidebar from './sidebarComponents/Primary.vue';
import SecondarySidebar from './sidebarComponents/Secondary.vue';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import router, { routesWithPermissions } from '../../routes';
import { hasPermissions } from '../../helper/permissionsHelper';
import { INBOX_TYPES } from '../../../shared/mixins/inboxMixin';
import types from '../../store/mutation-types';
import Cookies from 'js-cookie';

export default {
  components: {
    PrimarySidebar,
    SecondarySidebar,
  },
  mixins: [adminMixin, alertMixin, keyboardEventListenerMixins],
  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true,
    },
    sidebarClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
      isACustomBrandedInstance: 'globalConfig/isACustomBrandedInstance',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      labels: 'labels/getLabelsOnSidebar',
      teams: 'teams/getMyTeams',
      isConversationSecondarySidebarShowing:
        'isConversationSecondarySidebarShowing',
    }),
    activeCustomView() {
      if (this.activePrimaryMenu.key === 'contacts') {
        return 'contact';
      }
      if (this.activePrimaryMenu.key === 'conversations') {
        return 'conversation';
      }
      return '';
    },
    customViews() {
      return this.$store.getters['customViews/getCustomViewsByFilterType'](
        this.activeCustomView
      );
    },
    isConversationActive() {
      return this.activePrimaryMenu.key === 'conversations';
    },
    isConversationOrContactActive() {
      return (
        this.activePrimaryMenu.key === 'contacts' ||
        this.activePrimaryMenu.key === 'conversations'
      );
    },
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    hasWhatsAppInbox() {
      return this.inboxes.some(
        (inbox) =>
          inbox?.additional_attributes?.apiInboxType === 'WhatsApp' ||
          inbox.channel_type === INBOX_TYPES.WHATSAPP
      );
    },
    primaryMenuItems() {
      const userPermissions = this.currentUser.permissions;
      const menuItems = this.sideMenuConfig.primaryMenu;

      return menuItems.filter((menuItem) => {
        const isAvailableForTheUser = hasPermissions(
          routesWithPermissions[menuItem.toStateName],
          userPermissions
        );

        if (!isAvailableForTheUser) {
          return false;
        }

        let isVisible = true;

        if (['messageTemplates', 'automations'].includes(menuItem.key)) {
          isVisible = this.hasWhatsAppInbox;
        }

        if (
          menuItem.alwaysVisibleOnChatwootInstances &&
          !this.isACustomBrandedInstance
        ) {
          return true;
        }

        if (menuItem.featureFlag) {
          return (
            this.isFeatureEnabledonAccount(
              this.accountId,
              menuItem.featureFlag
            ) && isVisible
          );
        }

        return true;
      });
    },
    activeSecondaryMenu() {
      const { secondaryMenu } = this.sideMenuConfig;
      const { name: currentRoute } = this.$route;

      const activeSecondaryMenu =
        secondaryMenu.find((menuItem) =>
          menuItem.routes.includes(currentRoute)
        ) || {};
      return activeSecondaryMenu;
    },
    activePrimaryMenu() {
      const activePrimaryMenu =
        this.primaryMenuItems.find(
          (menuItem) => menuItem.key === this.activeSecondaryMenu.parentNav
        ) || {};
      return activePrimaryMenu;
    },
  },

  watch: {
    activeCustomView() {
      this.fetchCustomViews();
    },
    activePrimaryMenu: {
      handler() {
        this.$store.commit(
          types.SET_CURRENT_ACTIVE_ROUTE,
          this.activePrimaryMenu.key
        );
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('inboxes/get');
    this.$store.dispatch('labels/get');
    this.$store.dispatch('notifications/unReadCount');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('attributes/get');
    this.fetchCustomViews();
    this.checkSecondarySidebarCookie();

    this.$nextTick(() => {
      this.updateWindowDimensions();
    });

    this.$store.commit(
      types.SET_CURRENT_ACTIVE_ROUTE,
      this.activePrimaryMenu.key
    );
  },
  methods: {
    checkSecondarySidebarCookie() {
      const showSecondarySidebarCookie = Cookies.get('show_secondary_sidebar');
      if (!showSecondarySidebarCookie) {
        if (window.innerWidth > 1426) {
          this.$store.commit(`${types.SHOW_CONVERSATION_SECONDARY_SIDEBAR}`);
        } else {
          this.$store.commit(`${types.HIDE_CONVERSATION_SECONDARY_SIDEBAR}`);
        }
      } else {
        const parsedValue = JSON.parse(showSecondarySidebarCookie);
        if (parsedValue && window.innerWidth > 1426) {
          this.$store.commit(`${types.SHOW_CONVERSATION_SECONDARY_SIDEBAR}`);
        } else {
          this.$store.commit(`${types.HIDE_CONVERSATION_SECONDARY_SIDEBAR}`);
        }
      }
    },
    updateWindowDimensions() {
      if (window.innerWidth < 1426 && this.showSecondarySidebar) {
        this.$emitter.emit(BUS_EVENTS.TOGGLE_SIDEMENU);
      }
    },
    fetchCustomViews() {
      if (this.isConversationOrContactActive) {
        this.$store.dispatch('customViews/get', this.activeCustomView);
      }
    },
    toggleKeyShortcutModal() {
      this.$emit('open-key-shortcut-modal');
    },
    closeKeyShortcutModal() {
      this.$emit('close-key-shortcut-modal');
    },
    getKeyboardEvents() {
      return {
        '$mod+Slash': this.toggleKeyShortcutModal,
        '$mod+Escape': this.closeKeyShortcutModal,
        'Alt+KeyC': {
          action: () => this.navigateToRoute('home'),
        },
        'Alt+KeyV': {
          action: () => this.navigateToRoute('contacts_dashboard'),
        },
        'Alt+KeyR': {
          action: () => this.navigateToRoute('account_overview_reports'),
        },
        'Alt+KeyS': {
          action: () => this.navigateToRoute('agent_list'),
        },
      };
    },
    navigateToRoute(routeName) {
      if (!this.isCurrentRouteSameAsNavigation(routeName)) {
        router.push({ name: routeName });
      }
    },
    isCurrentRouteSameAsNavigation(routeName) {
      return this.$route.name === routeName;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    toggleAccountModal() {
      this.$emit('toggle-account-modal');
    },
    showAddLabelPopup() {
      this.$emit('show-add-label-popup');
    },
    showAddListPopup(folderId = '') {
      this.$emit('show-add-list-popup', folderId);
    },
    showAddFolderPopup() {
      this.$emit('show-add-folder-popup');
    },
    showAddEntrypointPopup() {
      this.$emit('show-add-entrypoint-popup');
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },

    showEditListPopup(listId) {
      this.$emit('show-edit-list-popup', listId);
    },

    showDeleteListPopup(listId) {
      this.$emit('show-delete-list-popup', listId);
    },

    showArchivePopup(archiveType, id, unarchive = false) {
      this.$emit('show-archive-popup', archiveType, id, unarchive);
    },

    showEditFolderPopup(id) {
      this.$emit('show-edit-folder-popup', id);
    },

    showDeleteFolderPopup(id) {
      this.$emit('show-delete-folder-popup', id);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes slideIn {
  from {
    display: block;
    max-width: 0;
    opacity: 0;
  }
  to {
    @apply max-w-[var(--contact-panel-width)];
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
  from {
    @apply max-w-[var(--contact-panel-width)];
    opacity: 1;
  }
  to {
    max-width: 0;
    opacity: 0;
    display: none;
  }
}
</style>
