export const getters = {
  brandnamicContacts: (state) => state.brandnamicContacts,
  brandnamicContact: (state) => state.brandnamicContact,
  brandnamicIsFetching: (state) => state.uiFlags.isFetching,
  brandnamicMeta: (state) => state.brandnamicMeta,
  brandnamicPersonalitiesMap: (state) => state.brandnamicPersonalitiesMap,
  brandnamicActivePersonality: (state) => state.brandnamicActivePersonality,
  brandnamicSelectedPersonalities: (state) =>
    state.brandnamicSelectedPersonalities,
};
